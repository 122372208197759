import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataAnalysisChart from './components/data-analysis-chart';
import Button from '../../shared-components/button/button';
import { MuteIcon, PauseIcon } from '../../assets/svgicons/svgicon';
import Unconfirmed from './components/unconfirmed-tab';

function AlarmMonitoring() {
  const { t } = useTranslation();
  const [buttonStatus, setButtonStatus] = useState('');
  const buttonGroup = [
    {
      label: t('mute'),
      icon: <MuteIcon />,
      key: 'mute',
    },
    {
      label: t('suspend'),
      icon: <PauseIcon />,
      key: 'suspend',
    },
  ];
  const data = [
    { name: 'Danger', value: 85, color: '#F41120' },
    { name: 'Medium', value: 85, color: '#03D733' },
    { name: 'Strong', value: 85, color: '#3D93F5' },
    { name: 'Weak', value: 0, color: '#10427C' },
  ];
  const alarmsEventData = [
    {
      label: t('disconnected'),
      value: '200',
      key: 'disconnected',
    },
    {
      label: t('templeAlarm'),
      value: '54',
      key: 'templeAlarm',
    },
    {
      label: t('cannotConnect'),
      value: '40',
      key: 'cannotConnect',
    },
    {
      label: t('doorOpenedForce'),
      value: '11',
      key: 'doorOpenedForce',
    },
    {
      label: t('extendedOpenedForce'),
      value: '1',
      key: 'extendedOpenedForce',
    },
  ];
  const changeButton = (key: any) => {
    // If the clicked button is already selected, don't unselect it
    setButtonStatus((prev: any) => (prev === key ? '' : key));
  };
  console.log(buttonStatus);
  return (
    <div className="alarm-monitoring">
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('alarmMonitoring'),
          },
        ]}
      />
      <div className="grid-container">
        <div className="flex-box">
          <div className="chart-box">
            <CardBox title={t('dataAnalysis')}>
              <div className="chart-box-wrapper">
                <DataAnalysisChart data={data} />
              </div>
            </CardBox>
          </div>
          <div className="alarm-events">
            <CardBox title={t('top5AlarmEvents')}>
              <div className="alarm-events-container">
                {alarmsEventData?.map((item: any) => (
                  <div className="rows" key={item.key}>
                    <span className="label">{item.label}</span>
                    <h2 className="value">{item.value}</h2>
                  </div>
                ))}
              </div>
            </CardBox>
          </div>
          <div className="action-buttons">
            <CardBox>
              <div className="button-group">
                {buttonGroup?.map((btn: any) => (
                  <Button
                    onClickFunction={() => changeButton(btn?.key)}
                    title={btn?.label}
                    icon={btn?.icon}
                    className={
                      buttonStatus === btn?.key
                        ? 'btn btn-primary row-reverse'
                        : 'btn btn-outline-primary row-reverse'
                    }
                  />
                ))}
              </div>
              {console.log(buttonStatus)}
            </CardBox>
          </div>
        </div>
        <div className="record-table">
          <CardBox title={t('todayRecords')}>
            <Unconfirmed />
          </CardBox>
        </div>
      </div>
    </div>
  );
}

export default AlarmMonitoring;
