import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';

function BgVerificationOptions({ closeModal, selectedValues }: any) {
  const { t } = useTranslation();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [values, setValues] = useState({
    bgVerification: 'accessLevel',
    deviceOffline: 'disable',
    onlineStatus: 'on',
  });
  const onChangeValue = (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const selectedDevices = selectedValues
    .sort((a: any, b: any) => {
      // Sort such that online devices come before offline or disabled devices
      if (a.status === 'online' && b.status !== 'online') return -1; // Move `online` devices up
      if (a.status !== 'online' && b.status === 'online') return 1; // Move `offline`/`disabled` down
      return 0; // Maintain order if both are the same status
    })
    .map((data: any) => {
      let message = t('theDisabledDevicesCanNotBeOperated');
      if (data?.status === 'online') {
        message = `The devices which have disabled background verification `;
      } else if (data?.status === 'offline') {
        message = t('theOfflineDevicesCanNotBeOperated');
      } else if (data?.status === 'disable') {
        message = t('theDisabledDevicesCanNotBeOperated');
      }
      return {
        ...data,
        message,
        name: data?.name,
      };
    });
  const submit = () => {
    console.log('hi');
  };
  // if the inputs disabled based on the status is online or offline
  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDevices.map((device: any) =>
              device?.status === 'online' ? (
                <RadioButtonField
                  label=""
                  name="onlineStatus"
                  aliment="column"
                  data={[
                    {
                      label: device?.message,
                      id: 'on',
                      value: 'on',
                    },
                  ]}
                  value={values?.onlineStatus}
                  onChangeFunction={onChangeValue}
                />
              ) : (
                <div>
                  <h4 className="text-warning">{device?.message}</h4>
                  <span className="text-warning">{device?.name}</span>
                </div>
              ),
            )}
          </div>
          <div className="verification-options">
            <h4>{t('setBg-VerificationOptions')}</h4>
            <div className="input-row">
              <SelectBoxField
                name="bgVerification"
                label={t('backgroundVerification')}
                id="bgVerification"
                value={values?.bgVerification}
                onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                data={[
                  { label: t('enable'), id: 'enable' },
                  { label: t('disable'), id: 'disable' },
                ]}
                className=""
                disabled
              />
              <SelectBoxField
                name="deviceOffline"
                label={t('ifTheDeiceIsOffline')}
                id="deviceOffline"
                value={values?.deviceOffline}
                onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                data={[
                  { label: t('standardAccessLevel'), id: 'accessLevel' },
                  { label: t('accessDenied'), id: 'accessDenied' },
                ]}
                className=""
                disabled
              />
            </div>
          </div>
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={!selectedDevices.map((device: any) => device.status).includes('online')}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && <div className="box" />}
        </div>
      </div>
    </div>
  );
}

export default BgVerificationOptions;
