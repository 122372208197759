import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
// import InputField from '../../../shared-components/input-field/input-field';

function SynchronizeTime({ closeModal }: any) {
  const { t } = useTranslation();
  const [showUpgrade, setShowUpgrade] = useState(true);
  return (
    <div className="device-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            <h4 className="text-warning">{t('The Disabled Devices Can Not be Operated !')}</h4>
            <span className="text-warning">Admin</span>
          </div>
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={t('hideInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              <div className="form-buttons-right">
                <Button
                  onClickFunction={() => {}}
                  title={t('synchronize')}
                  className="btn-primary"
                  disabled
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              <h4>Synchronize</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SynchronizeTime;
