import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
// import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import InputField from '../../../shared-components/input-field/input-field';
import { DoorInterface } from '../../../interface/device.interface';

function CommonPopup({ closeModal, selectedValues, inputTrue, modelName }: DoorInterface) {
  const { t } = useTranslation();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [doorValue, setDoorValue] = useState({
    openInterval: '5',
    userPassword: '',
  });
  const [disable, setDisable] = useState(false);
  const onChangeValue = (name: string, value: any) => {
    setDoorValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const selectedDoor = selectedValues.map((data: any) => {
    let message = t('theDisabledDevicesCanNotBeOperated');
    if (data?.enable === 'online') {
      message = `The devices which have disabled background verification `;
    } else if (data?.enable === 'offline') {
      message = t('theOfflineDevicesCanNotBeOperated');
    } else if (data?.enable === 'disable') {
      message = t('theDisabledDevicesCanNotBeOperated');
    }
    return {
      ...data,
      message,
      name: data?.doorName,
    };
  });
  const submit = () => {
    if (modelName === 'remoteOpen') {
      console.log('hi');
    } else if (modelName === 'remoteClose') {
      console.log('hi');
      // other condition implement start api integration
    }
  };

  useEffect(() => {
    const getOnlineDevice = selectedDoor.map((device: any) => device?.enable).includes('online');
    if (doorValue?.userPassword && getOnlineDevice) {
      if (modelName === 'remoteOpen' && doorValue?.openInterval) {
        setDisable(false);
      } else if (doorValue?.openInterval) {
        setDisable(false);
      }
    } else {
      setDisable(true);
    }
  }, [doorValue]);

  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDoor.map((door: any) =>
              door?.enable === 'online' ? (
                <span className="default-text">{door?.name}</span>
              ) : (
                <>
                  <h4 className="text-warning">{door?.message}</h4>
                  <span className="text-warning">{door?.name}</span>
                </>
              ),
            )}
          </div>
          {inputTrue && (
            <div className="column-new">
              <div className="row row-3">
                <InputField
                  name="userPassword"
                  label={t('userPassword')}
                  id="userPassword"
                  type="password"
                  value={doorValue?.userPassword}
                  innerIconStatus={false}
                  onChangeFunction={onChangeValue}
                  required
                />
                {modelName === 'remoteOpen' && (
                  <div className="open-interval">
                    <InputField
                      name="openInterval"
                      label={t('openInterval')}
                      id="openInterval"
                      type="number"
                      value={doorValue?.openInterval}
                      innerIconStatus={false}
                      onChangeFunction={onChangeValue}
                      required
                    />
                    <div className="seconds">
                      Second <span style={{ color: '#696C6F' }}>(1-254)</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={disable}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && <div className="box" />}
        </div>
      </div>
    </div>
  );
}

export default CommonPopup;
