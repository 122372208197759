import React, { useRef, useState } from 'react';
import { InputIpAddressInterface } from '../../interface/common.interface';

// Define the type for the IP segments state
interface IpSegments {
  segment1: string;
  segment2: string;
  segment3: string;
  segment4: string;
}
function InputIpAddressField({
  name,
  label,
  id,
  type,
  className,
  required,
  value,
  disabled,
  onChangeFunction = () => {},
}: InputIpAddressInterface) {
  const ipSegments: IpSegments = {
    segment1: value.split('.')[0] || '',
    segment2: value.split('.')[1] || '',
    segment3: value.split('.')[2] || '',
    segment4: value.split('.')[3] || '',
  };
  const [active, setActive] = useState<boolean>(false);
  const segmentRefs: any = useRef<Array<HTMLInputElement | null>>([]);

  // Handle change for each segment
  const handleSegmentChange = (e: any, segmentIndex: number) => {
    // // Allow only numeric input and restrict segment value to be between 0 and 255
    if (/^\d*$/.test(e.target.value) && Number(e.target.value) <= 255) {
      const newSegments = { ...ipSegments, [`segment${segmentIndex + 1}`]: e.target.value };
      const newIpAddress = Object.values(newSegments).join('.');
      onChangeFunction(e.target.name, newIpAddress); // Send updated IP address to parent
    }

    // Move focus to next segment if 3 digits are entered
    if (e.target.value.length === 3 && segmentIndex < 3) {
      segmentRefs.current[segmentIndex + 1].focus();
    }
  };

  // Handle backspace navigation between segments
  const handleKeyDown = (e: any, segmentIndex: number) => {
    const currentSegmentKey = `segment${segmentIndex + 1}` as keyof IpSegments; // Explicit type assertion
    if (e.key === 'Backspace' && ipSegments[currentSegmentKey] === '' && segmentIndex > 0) {
      segmentRefs.current[segmentIndex - 1]?.focus();
    }
  };

  return (
    <div
      id={id}
      className={`ip-address-input-form ${active ? 'active' : ''}`}
      onClick={() => setActive(!active)}
      onBlur={() => setActive(false)}
      role="presentation">
      {(['segment1', 'segment2', 'segment3', 'segment4'] as Array<keyof IpSegments>).map(
        (segment, index) => (
          <React.Fragment key={segment}>
            <input
              ref={(el) => {
                segmentRefs.current[index] = el; // Assign the ref without returning an assignment
              }}
              name={name}
              type={type}
              value={ipSegments[segment]}
              onChange={(e) => handleSegmentChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              maxLength={3}
              id={id}
              className={`input_field ${className}`}
              placeholder=""
              disabled={disabled}
            />
            {index < 3 && <span className="ip-dot">.</span>} {/* Display dot between segments */}
          </React.Fragment>
        ),
      )}
      <label className={`input-label ${className}`} htmlFor={id}>
        {label}
        {required && <span>*</span>}
      </label>
    </div>
  );
}

export default InputIpAddressField;
