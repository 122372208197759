import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/store';
import AddDayLight from './components/add-daylight-saving-time';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import { AddIcon } from '../../assets/svgicons/svgicon';

function Daylightsavingtime() {
  const { t } = useTranslation();
  const { totalDataCount, searchFilter } = useAppSelector((state: any) => state.cards);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('daylight'),
    content: <>hi</>,
  });
  const [selectedCards, setSelectedCards] = useState<string[]>([]);

  const columnsField = [
    {
      accessorKey: 'dstName',
      header: t('dstName'),
    },
    {
      accessorKey: 'startTime',
      header: t('startTime'),
    },
    {
      accessorKey: 'endTime',
      header: t('endTime'),
    },
    {
      accessorKey: 'timeZone',
      header: t('timeZone'),
    },
  ];

  // Mock Data for Table
  const readerData = [
    {
      id: 1,
      dstName: 'Alaska',
      startTime: 'MAY Third Sunday 2o’clock',
      endTime: 'NOV First Sunday 2o’clock',
      timeZone: 'UTC-12',
    },
    {
      id: 2,
      dstName: 'Amsterdam',
      startTime: 'MAY Third Sunday 2o’clock',
      endTime: 'NOV First Sunday 2o’clock',
      timeZone: 'UTC-09',
    },
  ];

  // Action buttons for Edit and Delete
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => {
        if (!modalOpen) {
          setModalOpen(true);
          setModalView({
            title: t('edit'),
            content: (
              <AddDayLight closeModal={() => setModalOpen(false)} data={{ ...val, update: true }} />
            ),
          });
        }
      },
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={() => {
                console.log('Delete confirmed for id:', val.id);
                setModalOpen(false);
              }}
            />
          ),
        });
      },
    },
  ];

  const getFilterData = () => {
    return {};
  };

  const fetchNextPage = () => {
    return {};
  };

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('daylightsavingtime'),
          },
        ]}
      />
      <CardBox
        title={t('listOfDayLightSavingTime')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddDayLight closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards}
          getSelectedData={(val: any): any => setSelectedCards(val)}
          checkBoxActive
          fetchNextPage={fetchNextPage}
          values={readerData}
          fixedColumn={['cardNo']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Daylightsavingtime;
