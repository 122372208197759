import React, { useState } from 'react';
import { CheckBoxActiveIcon, CheckBoxIcon } from '../../assets/svgicons/svgicon';
import { NormalTableComponentInterFace } from '../../interface/common.interface';

function BadgeComponent({ label, style }: any) {
  const customStyle = {
    ...style,
    width: 'max-content',
    borderRadius: '3px',
    padding: '3px',
  };
  return <div style={customStyle}>{label}</div>;
}

function Table({
  header,
  value,
  returnKey = 'id',
  checkBoxStatus = false,
  selectedValues = [],
  onSelectData = () => {},
  onSelect = () => {}, // Add onSelect prop to handle selected rows
  getSelectedFullData = () => {}, // get the full row data
}: NormalTableComponentInterFace) {
  const [selectedRows, setSelectedRows] = useState<any[]>(selectedValues);

  // Refactor selectRow to handle row selection with checkboxes
  const handleRowSelection = (row: any) => {
    const isSelected = selectedRows.includes(row[returnKey]);
    const updatedSelection = isSelected
      ? selectedRows.filter((selectedRow) => selectedRow !== row[returnKey])
      : [...selectedRows, row[returnKey]];
    setSelectedRows(updatedSelection);
    onSelectData(updatedSelection); // Update the parent component with selected values
    getSelectedFullData(row);
    if (onSelect) {
      onSelect(updatedSelection); // Pass the selected rows to the onSelect prop
    }
  };
  // after start the api integration change the styles in reusable
  // also check how to hover show the full wrapped text
  const getRowStyles = (priority: any) => {
    let backgroundColor = 'transparent';
    let color = 'inherit';
    if (priority === 'danger') {
      backgroundColor = '#f411201a';
      color = '#f41120';
    } else if (priority === 'medium') {
      backgroundColor = '#e0ffe0';
      color = '#03d733';
    } else if (priority === 'weak') {
      backgroundColor = '#e0f4ff';
      color = '#10427C';
    } else if (priority === 'strong') {
      backgroundColor = '#3D93F51A';
      color = '#3D93F5';
    }
    return { backgroundColor, color };
  };
  return (
    <table className="table">
      <thead>
        <tr>
          {checkBoxStatus && (
            <td>
              <div
                className="checkbox-icon"
                onClick={
                  () =>
                    selectedRows.length === value.length
                      ? setSelectedRows([]) // Unselect all
                      : setSelectedRows(value.map((row) => row[returnKey])) // Select all
                }
                role="presentation">
                {selectedRows.length === value.length ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
              </div>
            </td>
          )}
          {header.map((head) => (
            <td key={head.id}>{head.label}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        {value.map((dataValue) => (
          <tr key={dataValue[returnKey]}>
            {checkBoxStatus && (
              <td>
                <div
                  className="checkbox-icon"
                  onClick={() => handleRowSelection(dataValue)}
                  role="presentation">
                  {selectedRows.includes(dataValue[returnKey]) ? (
                    <CheckBoxActiveIcon />
                  ) : (
                    <CheckBoxIcon />
                  )}
                </div>
              </td>
            )}
            {header.map((head) => (
              <td key={head.id}>
                {head.id === 'priority' ? (
                  <BadgeComponent
                    label={dataValue[head.id]}
                    style={getRowStyles(dataValue[head.id]?.toLowerCase())}
                  />
                ) : (
                  <span>{dataValue[head.id]}</span>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
