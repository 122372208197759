import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import Table from '../../../shared-components/table/table';
import { ErrorIcon } from '../../../assets/svgicons/svgicon';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import SearchAddDevice from './search-add-device';
// import CircularLoader from '../../../shared-components/circular-loader/circular-loader';

function SearchDevice({ closeModal }: any) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState({
    ipAddress: '',
    deviceType: '',
    serialNumber: '',
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('add'),
    content: <>hi</>,
  });
  const onChange = (name: string, changeValue: any) =>
    setSearchValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  const searchFunction = () => {
    console.log('hi');
  };

  const clickAddDevice = (item: any) => {
    setModalOpen(true);
    setModalView({
      title: t('add'),
      content: <SearchAddDevice closeModal={() => setModalOpen(false)} data={item} />,
    });
  };
  const datas = [
    {
      ipAddress: '12221',
      macAddress: '96:26:ad:6c:0c:77',
      subnetMask: '96:26:ad:6c:0c:77',
      gatewayAddress: '192.168.1.025',
      serialNumber: 'Online',
      deviceType: 'G4 Pro',
      setServer: 'setServer',
      operation: 'This device has been added',
    },
    {
      ipAddress: '12221',
      macAddress: '96:26:ad:6c:0c:77',
      subnetMask: '96:26:ad:6c:0c:77',
      gatewayAddress: '192.168.1.025',
      serialNumber: 'Online',
      deviceType: 'G4 Pro',
      setServer: 'setServer',
      operation: 'Not added',
    },
  ];
  const filterDatas = datas.map((item) => ({
    ...item,
    operation:
      item.operation === 'Not added' ? (
        <div className="search-add-link" role="presentation" onClick={() => clickAddDevice(item)}>
          Add
        </div>
      ) : (
        item.operation
      ),
  }));
  return (
    <div className="search-device">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView?.title}
        content={modalView?.content}
      />
      <div className="title">
        <span>{t('noDeviceFound?')}</span>
        <span className="download-link">{t('downloadSearch')}</span>
      </div>
      <div className="row">
        <div className="input-row">
          <InputField
            name="ipAddress"
            label={t('ipAddress')}
            id="ipAddress"
            type="text"
            value={searchValue.ipAddress}
            onChangeFunction={onChange}
          />
          <InputField
            name="deviceType"
            label={t('deviceType')}
            id="deviceType"
            type="text"
            value={searchValue.deviceType}
            onChangeFunction={onChange}
          />
          <InputField
            name="serialNumber"
            label={t('serialNumber')}
            id="serialNumber"
            type="text"
            value={searchValue.serialNumber}
            onChangeFunction={onChange}
          />
        </div>
        <div className="search-wrapper">
          <Button
            onClickFunction={() => searchFunction}
            title={t('search')}
            className="btn-outline-primary"
          />
          {/* <CircularLoader /> */}
          <div className="search-loader">loading</div>
          <div className="search-result">
            <span style={{ paddingRight: '5px' }}>{t('searchDevicesCount')} 17</span>
            <span>{t('numberOfDevicesAdded')} 15</span>
          </div>
        </div>
      </div>
      <div className="device-table">
        <Table
          header={[
            { label: t('ipAddress'), id: 'ipAddress' },
            { label: t('macAddress'), id: 'macAddress' },
            { label: t('subnetMask'), id: 'subnetMask' },
            { label: t('gatewayAddress'), id: 'gatewayAddress' },
            { label: t('serialNumber'), id: 'serialNumber' },
            { label: t('deviceType'), id: 'deviceType' },
            { label: t('setServer'), id: 'setServer' },
            { label: t('operation'), id: 'operation' },
          ]}
          value={filterDatas} // Display the current issued cards
        />
      </div>
      <div className="device-bottom">
        <span className="error-message">
          <ErrorIcon />
          {t('deviceErrorMsg')}
        </span>
        <div className="form-buttons-right">
          {/* Trigger the batchCardIssue API when clicking the Done button */}
          <Button onClickFunction={closeModal} title={t('close')} className="btn-primary" />
        </div>
      </div>
    </div>
  );
}

export default SearchDevice;
