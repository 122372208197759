import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import { Device, DeviceSelectionModalProps } from '../../../interface/accessIoBoard.interface';

function DeviceSelectionModal({ closeModal, onSelectDevice }: DeviceSelectionModalProps) {
  const { t } = useTranslation();

  const availableDevices: Device[] = [{ name: 'Alarm_test', serial: 'CEFK222860292' }];

  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [filteredAvailableDevices, setFilteredAvailableDevices] =
    useState<Device[]>(availableDevices);
  const [deviceNameFilter, setDeviceNameFilter] = useState('');
  const [serialNumberFilter, setSerialNumberFilter] = useState('');
  const [selectedAvailableDevices, setSelectedAvailableDevices] = useState<Device[]>([]);
  const [selectedInSelectedDevices, setSelectedInSelectedDevices] = useState<Device[]>([]);

  const handleFilterChange = () => {
    setFilteredAvailableDevices(
      availableDevices.filter(
        (device) =>
          device.name.includes(deviceNameFilter) && device.serial.includes(serialNumberFilter),
      ),
    );
  };

  // Move selected devices from available to selected
  const handleAddSelectedDevices = () => {
    setSelectedDevices([...selectedDevices, ...selectedAvailableDevices]);
    setFilteredAvailableDevices(
      filteredAvailableDevices.filter((device) => !selectedAvailableDevices.includes(device)),
    );
    setSelectedAvailableDevices([]);
  };

  // Move selected devices from selected to available
  const handleRemoveSelectedDevices = () => {
    setFilteredAvailableDevices([...filteredAvailableDevices, ...selectedInSelectedDevices]);
    setSelectedDevices(
      selectedDevices.filter((device) => !selectedInSelectedDevices.includes(device)),
    );
    setSelectedInSelectedDevices([]);
  };

  const handleSubmit = () => {
    onSelectDevice(selectedDevices);
    closeModal();
  };

  const toggleDeviceInAvailable = (device: Device) => {
    if (selectedAvailableDevices.includes(device)) {
      setSelectedAvailableDevices(
        selectedAvailableDevices.filter((d) => d.serial !== device.serial),
      );
    } else {
      setSelectedAvailableDevices([...selectedAvailableDevices, device]);
    }
  };

  const toggleDeviceInSelected = (device: Device) => {
    if (selectedInSelectedDevices.includes(device)) {
      setSelectedInSelectedDevices(
        selectedInSelectedDevices.filter((d) => d.serial !== device.serial),
      );
    } else {
      setSelectedInSelectedDevices([...selectedInSelectedDevices, device]);
    }
  };

  return (
    <div className="device-selection-modal">
      <div className="header">
        <h3>{t('selectaDevice')}</h3>
        <Button onClickFunction={closeModal} title="X" className="btn-close" />
      </div>
      <div className="filters">
        <input
          type="text"
          placeholder={t('deviceName')}
          value={deviceNameFilter}
          onChange={(e) => setDeviceNameFilter(e.target.value)}
          onBlur={handleFilterChange}
        />
        <input
          type="text"
          placeholder={t('serialNumber')}
          value={serialNumberFilter}
          onChange={(e) => setSerialNumberFilter(e.target.value)}
          onBlur={handleFilterChange}
        />
      </div>
      <div className="content">
        <div className="device-tables">
          <div className="available-devices">
            <h4>{t('availableDevices')}</h4>
            <table>
              <thead>
                <tr>
                  <th>{t('deviceName')}</th>
                  <th>{t('serialNumber')}</th>
                </tr>
              </thead>
              <tbody>
                {filteredAvailableDevices.map((device) => (
                  <tr key={device.serial}>
                    <td>
                      <RadioButtonField
                        name="deviceSelectionAvailable"
                        aliment="column"
                        position="left"
                        value={selectedAvailableDevices.includes(device) ? device.serial : ''}
                        data={[{ label: device.name, id: device.serial, value: device.serial }]}
                        onChangeFunction={() => toggleDeviceInAvailable(device)}
                      />
                    </td>
                    <td>{device.name}</td>
                    <td>{device.serial}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="buttons">
            <Button onClickFunction={handleAddSelectedDevices} title=">" className="btn-default" />
            <Button
              onClickFunction={handleRemoveSelectedDevices}
              title="<"
              className="btn-default"
            />
          </div>
          <div className="selected-devices">
            <h4>
              {t('selectedDevices')} ({selectedDevices.length})
            </h4>
            <table>
              <thead>
                <tr>
                  <th>{t('deviceName')}</th>
                  <th>{t('serialNumber')}</th>
                </tr>
              </thead>
              <tbody>
                {selectedDevices.map((device) => (
                  <tr key={device.serial}>
                    <td>
                      <RadioButtonField
                        name="deviceSelectionSelected"
                        aliment="column"
                        position="left"
                        value={selectedInSelectedDevices.includes(device) ? device.serial : ''}
                        data={[{ label: device.name, id: device.serial, value: device.serial }]}
                        onChangeFunction={() => toggleDeviceInSelected(device)}
                      />
                    </td>
                    <td>{device.name}</td>
                    <td>{device.serial}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => closeModal()}
          title={t('cancel')}
          className="btn-default btn-bold"
        />
        <Button onClickFunction={handleSubmit} title={t('ok')} className="btn-primary btn-bold" />
      </div>
    </div>
  );
}

export default DeviceSelectionModal;
