import React, { useState, useEffect } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own components
// import BatchIssueCard from './components/batch-issue-card';
import AddDevice from './components/add-device';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import DeviceExportData from './components/export-data';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
// import { setNotificationError } from '../../redux/slices/NotificationSlices';

// Use assets svg icons
import { AddIcon } from '../../assets/svgicons/svgicon';

// Import card slice actions
import { fetchCardList, setSearchFilter } from '../../redux/slices/CardSlice';

import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
// import EditDevice from './components/edit-device';
import SearchDevice from './components/search-device';
import ListDropdown from '../../shared-components/list-dropdown/list-dropdown';
import { controlOptions, setupOptions, viewGetOptions } from './device-options';
import UpgradeFirmware from './components/upgrade-firmware';
import SynchronizeTime from './components/synchronize-time';
import SynchronizeAllDataToDevice from './components/synchronize-allDataToDevice';
import BgVerificationOptions from './components/bg-verification-option';
import DeviceTimeZone from './components/device-timeZone';
import RegistrationDevice from './components/registration-device';
import GetPersonalInformation from './components/get-personal-information';
import GetTransaction from './components/get-transaction';
import ViewRulesOfDevices from './components/view-rulesOf-devices';

function DeviceList() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { cards, status, totalDataCount, searchFilter, currentPageNo } = useAppSelector(
    (state: any) => state.cards,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('batchIssueCard'),
    content: <>hi</>,
  });
  const [selectedCards, setSelectedCards] = useState<string[]>([]); // Add state to hold selected cards
  const [selectedData, setSelectedData] = useState<string[]>([]); // Add state to hold selected cards
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('deviceName'),
    },
    {
      accessorKey: 'serialNumber',
      header: t('serialNumber'),
      size: 150,
    },
    {
      accessorKey: 'areaName',
      header: t('areaName'),
      size: 150,
    },
    {
      accessorKey: 'ipAddress',
      header: t('ipAddress'),
      size: 150,
    },
    {
      accessorKey: 'status',
      header: t('status'),
      size: 150,
    },
    {
      accessorKey: 'deviceModel',
      header: t('deviceModel'),
    },
    {
      accessorKey: 'registerDevice',
      header: t('registerDevice'),
      size: 150,
      Cell: ({ row }: any) => {
        let result;
        if (row?.original?.registerDevice === 'Yes') {
          result = <span style={{ color: '#00A76F' }}>Registered</span>;
        } else {
          result = <span style={{ color: '#E8212E' }}>Not Registered</span>;
        }
        return result;
      },
    },
    {
      accessorKey: 'firmwareVersion',
      header: t('firmwareVersion'),
    },
    {
      accessorKey: 'commandsList',
      header: t('commandsList'),
    },
  ];

  const fetchData = async (params: any) => {
    try {
      if (status === 'success') {
        dispatch(fetchCardList({ ...params }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const exportData = () => {
  //   setModalOpen(false);
  // };
  // const reported = () => {
  //   const request = selectedCards.join('&ids=');

  //   cardApi
  //     .batchCardLoss(request)
  //     .then((response: any) => {
  //       if (response.data.code === 0) {
  //         dispatch(
  //           setNotificationError({
  //             error: t('reportedLostCardSuccess'),
  //             status: 200,
  //           }),
  //         );
  //         setModalOpen(false);
  //         setSelectedCards([]);
  //       } else {
  //         dispatch(
  //           setNotificationError({
  //             error: response.data.message,
  //             status: response.data.code,
  //           }),
  //         );
  //       }
  //     })
  //     .catch((error: any) => {
  //       dispatch(
  //         setNotificationError({
  //           error: error.message,
  //           status: error.response?.status || 500,
  //         }),
  //       );
  //       setSelectedCards([]);
  //     });
  // };
  // const handleDropdownChange = (selectedLabel: string) => {
  //   console.log('Selected Label:', selectedLabel);
  // };

  const deleteRecord = () => {
    setModalOpen(false);
    // wiegantFormat
    //   .deleteWiegandFormat(id)
    //   .then((response) => {
    //     if (response.data.code === 0) {
    //       dispatch(
    //         setNotificationError({
    //           error: t('wiegandDeletedSuccessfully'),
    //           status: 200,
    //         }),
    //       );
    //       dispatch(deleteWiegandFormat(id));
    //       setModalOpen(false);
    //       fetchWiegandData({ pageNo: 1 }); // Refresh the list after successful deletion
    //     } else {
    //       dispatch(
    //         setNotificationError({
    //           error: response.data.message,
    //           status: response.data.code,
    //         }),
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     dispatch(
    //       setNotificationError({
    //         error: error.message,
    //         status: error.response?.status || 500,
    //       }),
    //     );
    //   });
  };

  const exportData = (data: any) => {
    console.log(data);
    setModalOpen(false);
  };

  const handleClickControl = (value: string = '') => {
    if (selectedCards.length > 0) {
      if (value === 'clearAdminPermission') {
        setModalOpen(true);
        setModalView({
          title: t('selectObject'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => setModalOpen(false)}
              title={t('areYouSureToClearDeviceAdministratorPermissions')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
      } else if (value === 'rebootDevice') {
        setModalOpen(true);
        setModalView({
          title: t('rebootDevice'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => setModalOpen(false)}
              title={t('areYouWantToPerformTheRebootDeviceOperation')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
      } else if (value === 'enable') {
        setModalOpen(true);
        setModalView({
          title: t('enable'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => setModalOpen(false)}
              title={t('areYouWantToPerformTheEnableOperation')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
      } else if (value === 'disable') {
        setModalOpen(true);
        setModalView({
          title: t('disable'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => setModalOpen(false)}
              title={t('areYouWantToPerformTheDisableOperation')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
      } else if (value === 'upgradeFirmware') {
        setModalOpen(true);
        setModalView({
          title: t('upgradeFirmware'),
          content: <UpgradeFirmware closeModal={() => setModalOpen(false)} />,
        });
      } else if (value === 'synchronizeTime') {
        setModalOpen(true);
        setModalView({
          title: t('synchronizeTime'),
          content: <SynchronizeTime closeModal={() => setModalOpen(false)} />,
        });
      } else if (value === 'synchronizeAllDevices') {
        setModalOpen(true);
        setModalView({
          title: t('synchronizeAllDevices'),
          content: <SynchronizeAllDataToDevice closeModal={() => setModalOpen(false)} />,
        });
      }
    } else {
      setModalOpen(true);
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
    }
  };

  const changeControl = (value: any) => {
    handleClickControl(value);
  };

  // setup
  const handleClickSetup = (value: string = '') => {
    if (selectedCards.length > 0) {
      if (value === 'verificationOptions') {
        setModalOpen(true);
        setModalView({
          title: t('Set Bg - Verification Options'),
          content: (
            <BgVerificationOptions
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
            />
          ),
        });
      } else if (value === 'setDeviceTimeZone') {
        setModalOpen(true);
        setModalView({
          title: t('setDeviceTimeZone'),
          content: (
            <DeviceTimeZone closeModal={() => setModalOpen(false)} selectedValues={selectedData} />
          ),
        });
      } else if (value === 'registrationDevice') {
        if (selectedCards.length === 1) {
          setModalOpen(true);
          setModalView({
            title: t('registrationDevice'),
            content: (
              <RegistrationDevice
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      }
    } else {
      setModalOpen(true);
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
    }
  };

  const changeSetup = (value: any) => {
    handleClickSetup(value);
  };

  // view-get
  const handleClickViewGet = (value: string = '') => {
    if (selectedCards.length > 0) {
      if (value === 'getDeviceOption') {
        setModalOpen(true);
        setModalView({
          title: t('getDeviceOption'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => setModalOpen(false)}
              title={t('areYouWantToPerformTheGetDeviceOptionOperation')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
      } else if (value === 'getPersonnelInformation') {
        if (selectedCards.length === 1) {
          setModalOpen(true);
          setModalView({
            title: t('getPersonnelInformation'),
            content: (
              <GetPersonalInformation
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      } else if (value === 'getTransactions') {
        if (selectedCards.length === 1) {
          setModalOpen(true);
          setModalView({
            title: t('getTransactions'),
            content: (
              <GetTransaction
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      } else if (value === 'viewRulesOfDevices') {
        if (selectedCards.length === 1) {
          setModalOpen(true);
          setModalView({
            title: t('viewRulesOfDevices'),
            content: (
              <ViewRulesOfDevices
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      }
    } else {
      setModalOpen(true);
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
    }
  };

  const changeViewGet = (value: any) => {
    handleClickViewGet(value);
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('editDevice'),
          content: <AddDevice closeModal={() => setModalOpen(false)} data={{ ...val }} />,
        });
      },
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteDevice'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
    {
      label: t('viewCommand'),
      function: (val: any) => {
        console.log(val);
      },
    },
  ];

  const moreActionButton = [
    {
      title: t('search'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('batchIssueCard'),
          content: <SearchDevice closeModal={() => setModalOpen(false)} />,
        });
      },
    },
    {
      title: t('export'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('export'),
          content: (
            <DeviceExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
      },
    },
    {
      dropdown: (
        <ListDropdown
          data={controlOptions}
          label={t('control')}
          changeFunction={changeControl}
          disabled={selectedCards.length > 0}
        />
      ),
      clickFunction: handleClickControl,
    },
    {
      dropdown: (
        <ListDropdown
          data={setupOptions}
          label={t('setup')}
          changeFunction={changeSetup}
          disabled={selectedCards.length > 0}
        />
      ),
      clickFunction: handleClickSetup,
    },
    {
      dropdown: (
        <ListDropdown
          data={viewGetOptions}
          label={t('view/get')}
          changeFunction={changeViewGet}
          disabled={selectedCards.length > 0}
        />
      ),
      clickFunction: handleClickViewGet,
    },
  ];

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  useEffect(() => {
    if (cards.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, []);
  const tempData = [
    {
      id: '0',
      name: 'test',
      serialNumber: 'VDE224170000',
      areaName: 'ZKTeco',
      ipAddress: '10.8.42.20',
      status: 'disable',
      deviceModel: 'Smart Terminal',
      registerDevice: 'Yes',
      firmwareVersion: 'Ver 9.0.1.5-20190725',
      commandsList: '208',
    },
    {
      id: '1',
      name: 'test-2',
      serialNumber: 'VDE224170000',
      areaName: 'ZKTeco',
      ipAddress: '10.8.42.20',
      status: 'offline',
      deviceModel: 'Smart Terminal',
      registerDevice: 'No',
      firmwareVersion: 'Ver 9.0.1.5-20190725',
      commandsList: '208',
    },
    {
      id: '2',
      name: 'test-3',
      serialNumber: 'VDE224170000',
      areaName: 'ZKTeco',
      ipAddress: '10.8.42.20',
      status: 'online',
      deviceModel: 'Smart Terminal',
      registerDevice: 'No',
      firmwareVersion: 'Ver 9.0.1.5-20190725',
      commandsList: '208',
    },
  ];
  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('device'),
          },
        ]}
      />
      <CardBox
        title={t('listOfDevice')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('addDevice')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('addDevice'),
                content: <AddDevice closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards} // Assign selected cards
          getSelectedData={(val: any): any => setSelectedCards(val)} // Set selected cards
          getSelectedFullData={(val: any): any => setSelectedData(val)}
          checkBoxActive
          fetchNextPage={fetchNextData}
          // isFetching={status === 'loading'}
          values={tempData}
          fixedColumn={['cardNo']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default DeviceList;
