import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/store';

// Import shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
import CommonPopup from './components/common-popup-auxiliary';
import ModalBox from '../../shared-components/modal-box/modal-box';
import EditReader from './components/edit-auxiliary';

function AuxiliaryOutput() {
  const { t } = useTranslation();
  const { totalDataCount, searchFilter } = useAppSelector((state: any) => state.cards);

  const columnsField = [
    {
      accessorKey: 'name',
      header: t('name'),
    },
    {
      accessorKey: 'deviceName',
      header: t('deviceName'),
    },
    {
      accessorKey: 'number',
      header: t('number'),
    },
    {
      accessorKey: 'printedName',
      header: t('printedName'),
    },
    {
      accessorKey: 'passageModeTimeZone',
      header: t('passageModeTimeZone'),
    },
    {
      accessorKey: 'owningBoard',
      header: t('owningBoard'),
    },
    {
      accessorKey: 'remarks',
      header: t('remarks'),
    },
  ];

  // Sample data for the table
  const auxiliaryData = [
    {
      name: 'Reader 1',
      deviceName: 'Main Door',
      passageModeTimeZone: ' Group Time Zone',
      printedName: 'main',
      owningBoard: 'Board 1',
      number: '123',
      id: '1',
    },
    {
      name: 'Reader 2',
      deviceName: 'Back Door',
      passageModeTimeZone: ' Single Time Zone',
      printedName: 'main',
      owningBoard: 'Board 2',
      number: '456',
      id: '2',
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedDoor, setSelectedDoor] = useState<string[]>([]);
  const [selectedData, setSelectedData] = useState<string[]>([]);

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => {
        if (!modalOpen) {
          setModalOpen(true);
          setModalView({
            title: t('edit'),
            content: (
              <EditReader
                initialData={{
                  deviceName: val.deviceName,
                  number: val.number,
                  name: val.name,
                  printedName: val.printedName,
                }}
                closeModal={() => setModalOpen(false)}
                saveChanges={() => {
                  // Handle save logic here
                }}
              />
            ),
          });
        }
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    console.log('Filter applied:', filter);
  };

  const moreActionButton = [
    {
      title: t('remoteOpen'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          console.log(selectedData);
          setModalView({
            title: t('remoteOpen'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                inputTrue
                modelName="remoteOpen"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('remoteClose'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('remoteClose'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                inputTrue
                modelName="remoteClose"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('remoteNormallyOpen'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('remoteNormallyOpen'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                inputTrue
                modelName="remoteNormallyOpen"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
  ];

  const fetchData = (): any => {
    // Function to fetch the next page of data, modify as needed
    console.log('Fetching data...');
  };

  useEffect(() => {
    console.log('Initial data fetching...');
  }, []);

  return (
    <div className="auxiliary-input">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('auxiliaryOutput'),
          },
        ]}
      />
      <CardBox
        title={t('listOfauxiliaryOutput')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
            buttonFunction={() => {
              console.log('Main action button clicked');
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedDoor}
          getSelectedData={(val: any): any => setSelectedDoor(val)}
          getSelectedFullData={(val: any): any => setSelectedData(val)}
          checkBoxActive
          fetchNextPage={fetchData}
          isFetching={false} // Placeholder status
          values={auxiliaryData} // Use the actual data here
          fixedColumn={['readerName']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default AuxiliaryOutput;
