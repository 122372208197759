import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import { AcknowledgeIcon, EditNotificationIcon } from '../../../assets/svgicons/svgicon';
import Table from '../../../shared-components/table/table';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import Acknowledge from './acknowledge';
import AlarmProcessingHistory from './alarmProcessingHistory';

function Unconfirmed() {
  const { t } = useTranslation();
  const tabs = [
    {
      key: 'all',
      title: t('all'),
    },
    {
      key: 'unconfirmed',
      title: t('unconfirmed'),
    },
    {
      key: 'processing',
      title: t('processing'),
    },
    {
      key: 'confirmed',
      title: t('confirmed'),
    },
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [tab, setTab] = useState<string>('all');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedFullData, setSelectedFullData] = useState<any[]>([]);
  const [recordData, setRecordData] = useState<any[]>([]);
  const moreActionButton: any = [
    {
      title: t('acknowledge'),
      icon: <AcknowledgeIcon />,
      clickFunction: (): void => {
        if (selectedRows.length === 1) {
          setModalView({
            title: t('acknowledge'),
            content: <Acknowledge closeModal={() => setModalOpen(false)} data={selectedFullData} />,
          });
          setModalOpen(true);
        } else if (selectedRows.length > 1) {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      },
    },
    {
      title: t('alarmProcessingHistory'),
      icon: <EditNotificationIcon />,
      clickFunction: (): void => {
        if (selectedRows.length === 1) {
          setModalView({
            title: t('alarmProcessingHistory'),
            content: (
              <AlarmProcessingHistory
                closeModal={() => setModalOpen(false)}
                data={selectedFullData}
              />
            ),
          });
          setModalOpen(true);
        } else if (selectedRows.length > 1) {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      },
    },
  ];
  const handleSelectRows = (selected: any[]) => {
    setSelectedRows(selected);
  };
  const tableData = [
    {
      time: '2024-07-10 12:10:am',
      device: 'TID_Vietnam(CSMN230860101)',
      eventPoint: '',
      eventDescription: 'Disconnected',
      person: '',
      belongAreas: 'Lobby',
      priority: 'Danger',
      id: '1',
      status: 'Confirmed',
    },
    {
      time: '2024-07-10 12:10:am',
      device: 'TID_Vietnam(CSMN230860101)',
      eventPoint: '',
      eventDescription: 'Disconnected',
      person: '',
      belongAreas: 'Lobby',
      priority: 'Weak',
      id: '2',
      status: 'Unconfirmed',
    },
    {
      time: '2024-07-10 12:10:am',
      device: 'TID_Vietnam(CSMN230860101)',
      eventPoint: '',
      eventDescription: 'Disconnected',
      person: '',
      belongAreas: 'Lobby',
      priority: 'Danger',
      id: '3',
      status: 'Processing',
    },
    {
      time: '2024-07-10 12:10:am',
      device: 'TID_Vietnam(CSMN230860101)',
      eventPoint: '',
      eventDescription: 'Disconnected',
      person: '',
      belongAreas: 'Lobby',
      priority: 'Strong',
      id: '4',
      status: 'Processing',
    },
    {
      time: '2024-07-10 12:10:am',
      device: 'TID_Vietnam(CSMN230860101)',
      eventPoint: '',
      eventDescription: 'Disconnected',
      person: '',
      belongAreas: 'Lobby',
      priority: 'Medium',
      id: '5',
      status: 'Processing',
    },
  ];
  const changeTab = (tabKey: string) => {
    setTab(tabKey);
  };
  useEffect(() => {
    const filterData =
      tab !== 'all'
        ? tableData?.filter((table: any) => table?.status?.toLowerCase() === tab)
        : tableData;
    setRecordData(filterData);
  }, [tab]);

  useEffect(() => {
    setRecordData(tableData);
  }, []);

  return (
    <div className="unconfirmed">
      <div className="tab-list-items">
        {tabs.map((tabItem: any) => (
          <div
            className={`tab-item ${tabItem.key === tab && 'active'}`}
            onClick={() => changeTab(tabItem.key)}
            role="presentation">
            {tabItem.title}
          </div>
        ))}
      </div>
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="header-options">
        {moreActionButton?.map((item: any) => (
          <div className="option" role="presentation" onClick={item?.clickFunction}>
            <div className="label">
              {item.icon} {item?.title}
            </div>
          </div>
        ))}
      </div>
      <div className="table-wrapper">
        <Table
          header={[
            { label: t('time'), id: 'time' },
            { label: t('device'), id: 'device' },
            { label: t('eventPoint'), id: 'eventPoint' },
            { label: t('eventDescription'), id: 'eventDescription' },
            { label: t('person'), id: 'person' },
            { label: t('belongAreas'), id: 'belongAreas' },
            { label: t('priority'), id: 'priority' },
            { label: t('status'), id: 'status' },
          ]}
          value={recordData}
          checkBoxStatus
          selectedValues={selectedRows} // Track selected rows
          onSelect={handleSelectRows} // Handle selection
          getSelectedFullData={(value: any) => setSelectedFullData(value)}
        />
      </div>
    </div>
  );
}

export default Unconfirmed;
