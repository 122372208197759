import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field'; // New import for radio buttons
import { EditReaderProps } from '../../../interface/accessReader.interface';

function EditReader({ initialData, closeModal, saveChanges }: EditReaderProps) {
  const { t } = useTranslation();
  const [editData, setEditData] = useState(initialData);

  // Common onChange function to handle input changes
  const onChange = (name: string, value: any) => {
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="edit-reader-modal">
      <form className="edit-reader-form">
        <div className="input-component">
          <div className="input-doorName">
            <InputField
              id="doorName"
              value={editData.doorName}
              label={t('doorName')}
              type="text"
              name="doorName"
              onChangeFunction={(name, value) => onChange(name, value)}
            />
          </div>
          <div className="input-name">
            <InputField
              id="name"
              value={editData.name}
              label={t('name')}
              type="text"
              name="name"
              onChangeFunction={(name, value) => onChange(name, value)}
            />
          </div>
        </div>
        <div className="input-group">
          <InputField
            id="number"
            value={editData.number}
            label={t('number')}
            type="number"
            name="number"
            onChangeFunction={(name, value) => onChange(name, value)}
          />
          <div className="input-group">
            <RadioButtonField
              label={t('in/Out')}
              name="inOut"
              aliment="row"
              required
              data={[
                {
                  label: t('in'),
                  id: 'inRadio',
                  value: 'In',
                },
                {
                  label: t('out'),
                  id: 'outRadio',
                  value: 'Out',
                },
              ]}
              value={editData.inOut}
              onChangeFunction={(name, value) => onChange(name, value)}
            />
          </div>
        </div>
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => closeModal()}
            title={t('cancel')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={() => {
              saveChanges(editData);
              closeModal();
            }}
            title={t('ok')}
            className="btn-primary btn-bold"
          />
        </div>
      </form>
    </div>
  );
}

export default EditReader;
