import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
// import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';

function SynchronizeAllDataToDevice({ closeModal }: any) {
  const { t } = useTranslation();
  const checkboxData = [
    {
      label: t('Access Authority'),
      id: 'accessAuthority',
      value: 'accessAuthority',
    },
    {
      label: t('timeZoneHoliday'),
      id: 'timeZoneHoliday',
      value: 'timeZoneHoliday',
    },
    {
      label: t('doorParameters'),
      id: 'doorParameters',
      value: 'doorParameters',
    },
    {
      label: t('antiPassback'),
      id: 'antiPassback',
      value: 'antiPassback',
    },
    {
      label: t('firstPersonOpenDoor'),
      id: 'firstPersonOpenDoor',
      value: 'firstPersonOpenDoor',
    },
    {
      label: t('multiPersonOpenDoor'),
      id: 'multiPersonOpenDoor',
      value: 'multiPersonOpenDoor',
    },
    {
      label: t('linkage'),
      id: 'linkage',
      value: 'linkage',
    },
    {
      label: t('bgVerificationOptions'),
      id: 'bgVerificationOptions',
      value: 'bgVerificationOptions',
    },
  ];
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [value, setValue] = useState({
    devices: [],
  });
  const [selectText, setSelectText] = useState(t('selectAll'));
  const onChangeValue = (name: string, values: any) => {
    console.log(values);
    setValue((prev: any) => ({
      ...prev,
      [name]: values,
    }));
  };
  const clickSynchronize = () => {
    console.log('synchronize');
  };
  const clickSelectAllDevice = () => {
    if (value?.devices?.length === checkboxData.length) {
      // All devices are selected, so unselect all
      setValue((prevValue: any) => ({
        ...prevValue,
        devices: [], // Clear the `devices` array
      }));
      setSelectText(t('selectAll'));
    } else {
      const allDeviceValues = checkboxData?.map((device: any) => device.value);
      setValue((prevValue: any) => ({
        ...prevValue, // Keep other values like `standAlone` unchanged
        devices: allDeviceValues, // Select all devices
      }));
      setSelectText(t('unselectAll'));
    }
  };
  const adminStyledText = <span style={{ color: '#078DEE' }}>admin</span>;
  return (
    <div className="device-popup" style={{ width: '47vw' }}>
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            <RadioButtonField
              label=""
              {...adminStyledText}
              name="exportType"
              aliment="column"
              data={[{ label: t('standAloneDevice'), id: 'standAlone', value: 'standAlone' }]}
              value="standAlone"
              onChangeFunction={() => {}}
            />
          </div>
        </div>
        <div className="column-1">
          <div className="checkbox-wrapper">
            <div>
              <Button
                onClickFunction={clickSelectAllDevice}
                title={selectText}
                className="btn-primary"
              />
            </div>
            <CheckBoxInputField
              label=""
              name="devices"
              aliment="warp grid-2"
              required
              data={checkboxData}
              value={value.devices}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={t('hideInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              {/* <SpinnerLoader /> */}
              <div>
                <div className="form-buttons-right">
                  <Button
                    onClickFunction={clickSynchronize}
                    title={t('synchronize')}
                    className="btn-primary"
                    disabled
                  />
                  <Button
                    onClickFunction={() => {
                      closeModal();
                    }}
                    title={t('close')}
                    className="btn-default"
                  />
                </div>
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              <span>Data Processing, Please Wait.....</span>
              <span>Admin : Data Synchronizing, Please Wait.....</span>
              <span>Admin : Device Synchronous Data Commands Sent Successfully.....</span>
              <span>Completed</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SynchronizeAllDataToDevice;
