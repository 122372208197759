import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import { EditDoorInterface } from '../../../interface/device.interface';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';

function EditDoor({ closeModal, data }: EditDoorInterface) {
  const { t } = useTranslation();
  const verificationOptions = [
    { label: t('automaticIdentification'), id: 'auto' },
    { label: t('onlyPin'), id: 'onlyPin' },
    { label: t('onlyPassword'), id: 'onlyPassword' },
    { label: t('onlyCard'), id: 'onlyCard' },
    { label: t('card/password'), id: 'card/password' },
    { label: t('card+password'), id: 'card+password' },
    { label: t('face'), id: 'face' },
    { label: t('face+password'), id: 'face+password' },
    { label: t('face+card'), id: 'face+card' },
  ];
  const [editDoorValue, setEditDoorValue] = useState(data);
  const [disable, setDisable] = useState(false);
  const onChangeValue = (name: string, value: any) => {
    setEditDoorValue((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const submit = () => {
    console.log('hi');
  };
  useEffect(() => {
    if (
      editDoorValue?.doorName &&
      editDoorValue?.activeTimeZone &&
      editDoorValue?.verificationMode &&
      editDoorValue?.lockOpenDuration &&
      editDoorValue?.doorSensorDelay &&
      editDoorValue?.doorSensorType
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [editDoorValue]);

  return (
    <div className="edit-door">
      <div className="grid-row">
        <div className="col">
          <InputField
            name="deviceName"
            label={t('deviceName')}
            id="name"
            type="text"
            value={editDoorValue.deviceName}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
            required
          />
        </div>
        <div className="col">
          <InputField
            name="doorNumber"
            label={t('doorNumber')}
            id="name"
            type="text"
            value={editDoorValue.doorNumber}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
            required
          />
        </div>
        <div className="col">
          <InputField
            name="operateInterval"
            label={t('operateInterval')}
            id="name"
            type="text"
            value={editDoorValue.operateInterval}
            disabled
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
            validation
            message={t('second(0-254)')}
          />
        </div>
        <div className="col">
          <InputField
            name="anti_passback_duration"
            label={t('antiPassbackDurationOfEntrance')}
            id="name"
            type="text"
            value={editDoorValue?.anti_passback_duration}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
            required
            validation
            message={t('minute(0-120)')}
          />
        </div>
        <div className="col">
          <InputField
            name="doorName"
            label={t('doorName')}
            id="name"
            type="text"
            value={editDoorValue?.doorName}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="hostAccessStatus"
            label={t('hostAccessStatus')}
            id="hostAccessStatus"
            value={editDoorValue.hostAccessStatus}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('entry'), id: 'entry' },
              { label: t('out'), id: 'out' },
            ]}
            className=""
          />
        </div>
        <div className="col">
          <InputField
            name="lockOpenDuration"
            label={t('lockOpenDuration')}
            id="name"
            type="text"
            value={editDoorValue?.lockOpenDuration}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
            validation
            message={t('second(1-254)')}
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="doorSensorType"
            label={t('doorSensorType')}
            id="doorSensorType"
            value={editDoorValue.doorSensorType}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('none'), id: 'none' },
              { label: t('normallyOpen'), id: 'normallyOpen' },
              { label: t('normallyClosed'), id: 'normallyClosed' },
            ]}
            className=""
            required
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="verificationMode"
            label={t('verificationMode')}
            id="verificationMode"
            value={editDoorValue.verificationMode}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={verificationOptions}
            className=""
            required
          />
        </div>
        <div className="col">
          <InputField
            name="durationPassword"
            label={t('durationPassword')}
            id="name"
            type="password"
            value={editDoorValue?.duressPassword}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            validation
            message={t('maximum6BitInteger')}
          />
        </div>
        <div className="col">
          <InputField
            name="doorSensorDelay"
            label={t('doorSensorDelay')}
            id="name"
            type="text"
            value={editDoorValue?.doorSensorDelay}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
            disabled
          />
        </div>
        <div className="col">
          <InputField
            name="emergencyPassword"
            label={t('emergencyPassword')}
            id="name"
            type="text"
            value={editDoorValue?.emergencyPassword}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            validation
            message={t('8BitInteger')}
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="settingsCopy"
            label={t('settingsCopy')}
            id="settingsCopy"
            value={editDoorValue.settingsCopy}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[{ label: t('allDoorsAreStandAlone'), id: 'none' }]}
            className=""
            required
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="activeTimeZone"
            label={t('activeTimeZone')}
            id="activeTimeZone"
            value={editDoorValue.activeTimeZone}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[{ label: t('workingHours'), id: 'workingHours' }]}
            className=""
            required
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="passageModeTimeZone"
            label={t('passageModeTimezone')}
            id="passageModeTimeZone"
            value={editDoorValue.passageModeTimeZone}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[{ label: t('24hoursAccessible'), id: '24hoursAccessible' }]}
            className=""
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="slaveState"
            label={t('slaveOutOfState')}
            id="slaveState"
            value={editDoorValue.slaveState}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('entry'), id: 'entry' },
              { label: t('out'), id: 'out' },
            ]}
            className=""
          />
        </div>
      </div>
      <div className="switch-wrap">
        <CheckBoxInputField
          name="disableAlarm"
          data={[
            {
              label: t('disableAlarmSounds'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          value={editDoorValue.disableAlarm}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default btn-bold"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary btn-bold"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default EditDoor;
