/* eslint-disable */
import {
  BulletListIcon,
  ClearAdministrationPermissionIcon,
  DisableIcon,
  EnableIcon,
  ExtendedParametersIcon,
  GetPersonalInformationIcon,
  ModifyFingerprintThresholdIcon,
  RebootDeviceIcon,
  registrationDeviceIcon,
  ReplaceDeviceIcon,
  setDeviceTimeZoneIcon,
  SynchronizeAllDevicesIcon,
  SynchronizeTimeIcon,
  TransactionIcon,
  UpgradeFirmwareIcon,
  VerificationOptionsIcon,
  VideoIntercomServerIcon,
  ViewDeviceCapacityIcon,
  ViewRulesIcon,
} from '../../assets/svgicons/svgicon';
import { OptionType } from '../../interface/common.interface';
const controlOptions: OptionType[] = [
  {
    value: 'clearAdminPermission',
    label: 'clearAdminPermission',
    icon: ClearAdministrationPermissionIcon,
  },
  {
    value: 'upgradeFirmware',
    label: 'upgradeFirmware',
    icon: UpgradeFirmwareIcon,
  },
  {
    value: 'rebootDevice',
    label: 'rebootDevice',
    icon: RebootDeviceIcon,
  },
  {
    value: 'synchronizeTime',
    label: 'synchronizeTime',
    icon: SynchronizeTimeIcon,
  },
  {
    value: 'enable',
    label: 'enable',
    icon: EnableIcon,
  },
  {
    value: 'disable',
    label: 'disable',
    icon: DisableIcon,
  },
  {
    value: 'synchronizeAllDevices',
    label: 'synchronizeAllDevices',
    icon: SynchronizeAllDevicesIcon,
  },
];

const setupOptions: OptionType[] = [
  {
    value: 'verificationOptions',
    label: 'Set Bg - Verification Options',
    icon: VerificationOptionsIcon,
  },
  {
    value: 'setDeviceTimeZone',
    label: 'Set Device, Time Zone',
    icon: setDeviceTimeZoneIcon,
  },
  {
    value: 'registrationDevice',
    label: 'Set as Registration Device',
    icon: registrationDeviceIcon,
  },
  {
    value: 'modifyFingerprintThreshold',
    label: 'Modify the Fingerprint Identification Threshold',
    icon: ModifyFingerprintThresholdIcon,
  },
  {
    value: 'extendedParameters',
    label: 'Set Extended Parameters',
    icon: ExtendedParametersIcon,
  },
  {
    value: 'videoIntercomServer',
    label: 'Set Video Intercom Server',
    icon: VideoIntercomServerIcon,
  },
  {
    value: 'replaceDevice',
    label: 'Replace Device',
    icon: ReplaceDeviceIcon,
  },
];

const viewGetOptions: OptionType[] = [
  {
    value: 'getDeviceOption',
    label: 'getDeviceOption',
    icon: BulletListIcon,
  },
  {
    value: 'getPersonnelInformation',
    label: 'getPersonnelInformation',
    icon: GetPersonalInformationIcon,
  },
  {
    value: 'getTransactions',
    label: 'getTransactions',
    icon: TransactionIcon,
  },
  {
    value: 'viewRulesOfDevices',
    label: 'viewRulesOfDevices',
    icon: ViewRulesIcon,
  },
  {
    value: 'viewDeviceCapacity',
    label: 'viewDeviceCapacity',
    icon: ViewDeviceCapacityIcon,
  },
];


export { controlOptions, setupOptions, viewGetOptions };
