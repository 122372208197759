import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
// import InputField from '../../../shared-components/input-field/input-field';

function UpgradeFirmware({ closeModal }: any) {
  const { t } = useTranslation();
  const inputFile = useRef<HTMLInputElement>(null);
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [file, setFile] = useState(null);
  const onChangeFile = (e: any): void => {
    console.log(e.target.file[0]);
    setFile(e.target.file[0]);
  };
  const selectFile = () => {
    inputFile?.current?.click();
  };
  return (
    <div className="device-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">
            {t('selectedDevice')} (<span className="text-warning">{t('onlyUpgradeSameType')}</span>)
          </h3>
          <div className="box">
            <h4 className="text-warning">{t('The Disabled Devices Can Not be Operated !')}</h4>
          </div>
          <div className="file-wrapper">
            <div className="file-upload">
              <span className="file-select">Select File</span>
              <div className="brows-btn" onClick={selectFile} role="presentation">
                Browse
              </div>
              <span className="not-upload">Not uploaded</span>
              <input
                type="file"
                name=""
                id="file"
                ref={inputFile}
                // accept="image/*,video/*, application/pdf"
                onChange={onChangeFile}
                style={{ display: 'none' }}
              />
            </div>
          </div>
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={t('hideInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              <div className="form-buttons-right">
                <Button
                  onClickFunction={() => {}}
                  title={t('upgrading')}
                  className="btn-primary"
                  disabled={!file}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              <h4>Synchronize</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UpgradeFirmware;
