import React, { useEffect, useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use own components
import CardFormatTesting from './components/card-format-testing';
import AddWiegandFormat from './components/add-wiegand-format';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';

// Use assets svg icons
import { AddIcon } from '../../assets/svgicons/svgicon';

// Use assets json files
// import WiegandFormatData from '../../assets/json/wiegand-format-simple-data.json';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { WiegandFormatReduxInterface } from '../../interface/wiegandFormat.interface';
import {
  deleteWiegandFormat,
  fetchWiegandFormatList,
  setSearchFilter,
} from '../../redux/slices/WiegantSlice';
import EditWiegandFormat from './components/editWiegandFormat';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import wiegantFormat from '../../utils/api/wiegantFormat';

function WiegandFormat() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    wiegandFormats,
    status,
    totalDataCount,
    currentPageNo,
    searchFilter,
  }: WiegandFormatReduxInterface = useAppSelector((state: any) => state.wiegandFormat);
  const filterValue = {
    columnFilters: [],
    globalFilter: '',
    sorting: [],
  };
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('name'),
    },
    {
      accessorKey: 'wiegandMode',
      header: t('mode'),
      Cell: ({ row }: any) => {
        return row?.original?.wiegandMode === 1 ? 'Mode One' : 'Mode Two';
      },
    },
    {
      accessorKey: 'siteCode',
      header: t('siteCode'),
    },
    {
      accessorKey: 'autoMode',
      header: t('auto'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('addNewWiegandFormat'),
    content: <>hi</>,
  });

  const fetchWiegandData = (params: any) => {
    if (status === 'success') {
      dispatch(fetchWiegandFormatList({ ...params }));
    }
  };

  const deleteRecord = (id: any) => {
    wiegantFormat
      .deleteWiegandFormat(id)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('wiegandDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteWiegandFormat(id));
          setModalOpen(false);
          fetchWiegandData({ pageNo: 1 }); // Refresh the list after successful deletion
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => {
        const data = {
          ...val,
          autoMode: val?.autoMode === 'Yes' ? ['yes'] : [],
        };
        setModalOpen(true);
        setModalView({
          title: t('editWiegandFormat'),
          content: <EditWiegandFormat closeModal={() => setModalOpen(false)} data={{ ...data }} />,
        });
      },
    },
    {
      label: t('delete'),
      disabled: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteWiegandFormat'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const moreActionButton = [
    {
      title: t('cardFormatsTesting'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('cardFormatsTesting'),
          content: <CardFormatTesting closeModal={() => setModalOpen(false)} />,
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchWiegandData({ pageNo: 1, ...filter.filter });
    }
  };
  const fetchData = (): any => {
    fetchWiegandData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };
  useEffect(() => {
    if (wiegandFormats.length === 0) {
      fetchWiegandData({ pageNo: 1 });
    }
  }, []);
  return (
    <div className="wiegand-format">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('wiegandFormat')}
        broadCram={[
          {
            title: t('cardManagement'),
          },
          {
            title: t('wiegandFormat'),
          },
        ]}
      />
      <CardBox
        title={t('wiegandFormat')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('add')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('addNewWiegandFormat'),
                content: <AddWiegandFormat closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={filterValue}
          assignSelectedData={[]}
          getSelectedData={(val: any): any => console.log(val)}
          checkBoxActive
          fetchNextPage={fetchData}
          values={wiegandFormats}
          fixedColumn={['name']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default WiegandFormat;
