import React, { useEffect, useRef, useState } from 'react';

// Use interface
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { LocalizationProvider, StaticDateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { InputDateInterFace } from '../../interface/common.interface';

function InputDateTime({
  label,
  value = Dayjs,
  disabled,
  readonly = false,
  message,
  errorStatus,
  disablePast = false,
  okFunction,
  onChangeFunction = () => {},
}: InputDateInterFace) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const changeValue = (newValue: Dayjs | null) => {
    onChangeFunction(newValue);
  };
  const handleClickOutside = (event: MouseEvent) => {
    // Check if the click is outside the dropdown element
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false); // Close the dropdown when clicking outside
    }
  };

  const handleAccept = (newValue: any) => {
    okFunction(newValue.format('YYYY-MM-DD HH:mm'));
    setOpen(false);
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="form-item" ref={ref}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TextField
          label={label}
          value={value ? value.format('YYYY-MM-DD HH:mm') : ''}
          onClick={() => setOpen(true)} // Open DateTimePicker on input click
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          sx={{ position: 'relative' }}
        />
        {open && (
          <StaticDateTimePicker
            disablePast={disablePast}
            disabled={disabled}
            readOnly={readonly}
            onAccept={handleAccept}
            onClose={() => setOpen(false)}
            value={value}
            onChange={changeValue}
            slotProps={{
              layout: {
                sx: {
                  boxShadow: '0px 1px 8px 1px #0000001A',
                },
              },
              toolbar: {
                hidden: true, // Hides the entire toolbar
              },
            }}
            sx={{
              position: 'absolute',
              zIndex: 10,
              '& .MuiDateCalendar-root': {
                maxHeight: '237px',
              },
            }}
          />
        )}
      </LocalizationProvider>
      {errorStatus && <small className="input-error-message">{message}</small>}
    </div>
  );
}

export default InputDateTime;
