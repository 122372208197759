import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import InputDateTime from '../../../shared-components/input-time-date/input-time-date';

function GetTransaction({ closeModal, selectedValues }: any) {
  const { t } = useTranslation();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [value, setValue] = useState({
    transactionMode: 'newTransaction',
    validTime: [],
    startTime: null,
    endTime: null,
  });
  const options = [
    {
      label: t('getNewTransactions'),
      id: 'newTransaction',
      value: 'newTransaction',
    },
    {
      label: t('getAllTransactions'),
      id: 'allTransaction',
      value: 'allTransaction',
    },
  ];
  const selectedDevices = selectedValues
    .sort((a: any, b: any) => {
      // Sort such that online devices come before offline or disabled devices
      if (a.status === 'online' && b.status !== 'online') return -1; // Move `online` devices up
      if (a.status !== 'online' && b.status === 'online') return 1; // Move `offline`/`disabled` down
      return 0; // Maintain order if both are the same status
    })
    .map((data: any) => {
      let message = t('theDisabledDevicesCanNotBeOperated');
      if (data?.status === 'online') {
        message = `The devices which have disabled background verification `;
      } else if (data?.status === 'offline') {
        message = t('theOfflineDevicesCanNotBeOperated');
      } else if (data?.status === 'disable') {
        message = t('theDisabledDevicesCanNotBeOperated');
      }
      return {
        ...data,
        message,
        name: data?.name,
      };
    });
  const submit = () => {
    console.log('hi');
  };
  const onChangeValue = (name: string, values: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: values,
    }));
  };
  const changeDateTime = (data: any) => {
    setValue((prev: any) => ({
      ...prev,
      startTime: dayjs(data),
    }));
  };
  const changeDateEndTime = (data: any) => {
    setValue((prev: any) => ({
      ...prev,
      endTime: dayjs(data),
    }));
  };
  // Handle when the user clicks the OK button
  const handleAcceptStartTime = (data: any) => {
    setValue((prev: any) => ({
      ...prev,
      startTime: dayjs(data),
    }));
  };
  const handleAcceptEndTime = (data: any) => {
    setValue((prev: any) => ({
      ...prev,
      endTime: dayjs(data),
    }));
  };
  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDevices.map((device: any) =>
              device?.status === 'online' ? (
                <RadioButtonField
                  label=""
                  name="onlineStatus"
                  aliment="column"
                  data={[
                    {
                      label: device?.message,
                      id: 'on',
                      value: 'on',
                    },
                  ]}
                  value="on"
                  onChangeFunction={() => {}}
                />
              ) : (
                <div>
                  <h4 className="text-warning">{device?.message}</h4>
                  <span className="text-warning">{device?.name}</span>
                </div>
              ),
            )}
          </div>
          <RadioButtonField
            label=""
            name="transactionMode"
            aliment="row-wrap"
            data={options}
            value={value?.transactionMode}
            onChangeFunction={onChangeValue}
          />
          <CheckBoxInputField
            name="validTime"
            data={[
              {
                label: t('setValidTime'),
                id: 'yes',
                value: 'yes',
              },
            ]}
            value={value?.validTime}
            onChangeFunction={onChangeValue}
          />
          {value?.validTime?.[0] === 'yes' && (
            <div className="time-input">
              <InputDateTime
                label="start time"
                value={value?.startTime}
                onChangeFunction={changeDateTime}
                okFunction={handleAcceptStartTime}
              />
              <InputDateTime
                label="end time"
                value={value?.endTime}
                onChangeFunction={changeDateEndTime}
                okFunction={handleAcceptEndTime}
              />
            </div>
          )}
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={!selectedDevices.map((device: any) => device.status).includes('online')}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && <div className="box" />}
        </div>
      </div>
    </div>
  );
}

export default GetTransaction;
