import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/store';

// Import shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import EditReader from './components/edit-auxiliary';
import BindingUnbindingCameraModal from './components/binding-unbinding';

function AuxiliaryInput() {
  const { t } = useTranslation();
  const { totalDataCount, searchFilter } = useAppSelector((state: any) => state.cards);

  const columnsField = [
    {
      accessorKey: 'name',
      header: t('name'),
    },
    {
      accessorKey: 'deviceName',
      header: t('deviceName'),
    },
    {
      accessorKey: 'number',
      header: t('number'),
    },
    {
      accessorKey: 'printedName',
      header: t('printedName'),
    },
    {
      accessorKey: 'boundCamera',
      header: t('boundCamera'),
    },
    {
      accessorKey: 'owningBoard',
      header: t('owningBoard'),
    },
    {
      accessorKey: 'remarks',
      header: t('remarks'),
    },
  ];

  // Sample data for the table
  const auxiliaryData = [
    {
      name: 'Reader 1',
      deviceName: 'Main Door',
      boundCamera: 'Camera 1',
      printedName: 'main',
      owningBoard: 'Board 1',
      number: '123',
      id: 1,
    },
    {
      name: 'Reader 2',
      deviceName: 'Back Door',
      boundCamera: 'Camera 2',
      printedName: 'main',
      owningBoard: 'Board 2',
      number: '456',
      id: 2,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => {
        if (!modalOpen) {
          setModalOpen(true);
          setModalView({
            title: t('edit'),
            content: (
              <EditReader
                initialData={{
                  deviceName: val.deviceName,
                  number: val.number,
                  name: val.name,
                  printedName: val.printedName,
                }}
                closeModal={() => setModalOpen(false)}
                saveChanges={() => {
                  // Handle save logic here
                }}
              />
            ),
          });
        }
      },
    },
    {
      label: t('binding/unbindingcamera'),
      function: () => {
        if (!modalOpen) {
          setModalOpen(true);
          setModalView({
            title: t('binding/unbindingcamera'),
            content: (
              <BindingUnbindingCameraModal
                closeModal={() => setModalOpen(false)}
                onConfirm={() => console.log('OK clicked')}
              />
            ),
          });
        }
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    console.log('Filter applied:', filter);
  };

  const fetchData = (): any => {
    console.log('Fetching next page data...');
  };

  useEffect(() => {
    console.log('Initial data fetching...');
  }, []);

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('auxiliaryInput'),
          },
        ]}
      />
      <CardBox title={t('listOfauxiliaryInput')}>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={[]}
          getSelectedData={(val: any): any => console.log(val)}
          checkBoxActive
          fetchNextPage={fetchData}
          isFetching={false} // Placeholder status
          values={auxiliaryData} // Use the actual data here
          fixedColumn={['readerName']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default AuxiliaryInput;
