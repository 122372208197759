import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../shared-components/table/table';
import Button from '../../../shared-components/button/button';
import { ErrorIconTwo } from '../../../assets/svgicons/svgicon';

function ViewRulesOfDevices({ closeModal }: any) {
  const { t } = useTranslation();
  const datas = [
    {
      ruleType: 'Interlock',
      description: 'Not set',
      ruleDetails: 'None',
    },
    {
      ruleType: 'Interlock',
      description: 'Not set',
      ruleDetails: 'None',
    },
  ];
  return (
    <div className="view-rulesOf-devices">
      <Table
        header={[
          { label: t('ruleType'), id: 'ruleType' },
          { label: t('description'), id: 'description' },
          { label: t('ruleDetails'), id: 'ruleDetails' },
        ]}
        value={datas} // Display the current issued cards
      />
      <div className="bottom">
        <div className="text-message">
          <div>
            <ErrorIconTwo />
          </div>
          <span className="text-warning">{t('linkageGlobalLinkageAntiPassbackError')}</span>
        </div>
        <Button onClickFunction={closeModal} title={t('close')} className="btn-primary" />
      </div>
    </div>
  );
}

export default ViewRulesOfDevices;
