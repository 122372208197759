import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/store';

// Import shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
import CommonPopup from './components/common-popup';
import ListDropdown from '../../shared-components/list-dropdown/list-dropdown';
import { OptionType } from '../../interface/common.interface';
import {
  ActivateLockdownIcon,
  DeactivateLockdownIcon,
  DisableIndradayIcon,
  EnableIndradayIcon,
} from '../../assets/svgicons/svgicon';
import EditDoor from './components/edit-door';

function Door() {
  const { t } = useTranslation();
  const { totalDataCount, searchFilter } = useAppSelector((state: any) => state.cards);

  const columnsField = [
    {
      accessorKey: 'doorName',
      header: t('doorName'),
    },
    {
      accessorKey: 'areaName',
      header: t('areaName'),
    },
    {
      accessorKey: 'ownedDevice',
      header: t('ownedDevice'),
    },
    {
      accessorKey: 'serialNumber',
      header: t('serialNumber'),
    },
    {
      accessorKey: 'enable',
      header: t('enable'),
    },
    {
      accessorKey: 'activeTimeZone',
      header: t('activeTimezone'),
    },
    {
      accessorKey: 'doorSensorType',
      header: t('doorSensorType'),
    },
    {
      accessorKey: 'verificationMode',
      header: t('verificationMode'),
    },
    {
      accessorKey: 'owningBoard',
      header: t('owningBoard'),
    },
  ];

  const options: OptionType[] = [
    {
      value: 'activateLockdown',
      label: 'activateLockdown',
      icon: ActivateLockdownIcon,
    },
    {
      value: 'deactivateLockdown',
      label: 'deactivateLockdown',
      icon: DeactivateLockdownIcon,
    },
    {
      value: 'enableIntraday',
      label: 'enableIntradayPassageModeTimeZone',
      icon: EnableIndradayIcon,
    },
    {
      value: 'disableIntraday',
      label: 'disableIntradayPassageModeTimeZone',
      icon: DisableIndradayIcon,
    },
  ];

  // Sample data for the table
  const readerData = [
    {
      deviceName: 'Admin',
      doorName: 'Go account door',
      doorNumber: 5,
      areaName: 'Xiao copy',
      operateInterval: 1,
      anti_passback_duration: 1,
      ownedDevice: 'Go account Device',
      serialNumber: '12121212',
      enable: 'online',
      activeTimeZone: 'G4 Pro',
      doorSensorType: 'working-Hours',
      verificationMode: 'Automatic Identification',
      owningBoard: '',
      id: '0',
    },
    {
      deviceName: 'Admin',
      doorName: 'Go account door',
      doorNumber: 5,
      areaName: 'Xiao copy',
      operateInterval: 1,
      anti_passback_duration: 1,
      ownedDevice: 'Go account Device',
      serialNumber: '12121212',
      enable: 'online',
      activeTimeZone: 'G4 Pro',
      doorSensorType: 'working-Hours',
      verificationMode: 'Automatic Identification',
      owningBoard: '',
      id: '1',
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedDoor, setSelectedDoor] = useState<string[]>([]);
  const [selectedData, setSelectedData] = useState<string[]>([]);

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => {
        if (!modalOpen) {
          setModalOpen(true);
          setModalView({
            title: t('edit'),
            content: <EditDoor closeModal={() => setModalOpen(false)} data={{ ...val }} />,
          });
        }
      },
    },
  ];

  const handleClick = (value: string = '') => {
    if (selectedDoor.length > 0) {
      if (value === 'activateLockdown') {
        setModalView({
          title: t('activateLockdown'),
          content: (
            <CommonPopup
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
              inputTrue
              modelName="activateLockdown"
            />
          ),
        });
        setModalOpen(true);
      } else if (value === 'deactivateLockdown') {
        setModalView({
          title: t('deactivateLockdown'),
          content: (
            <CommonPopup
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
              inputTrue
              modelName="deactivateLockdown"
            />
          ),
        });
        setModalOpen(true);
      } else if (value === 'enableIntraday') {
        setModalView({
          title: t('enableIntradayPassageModeTimeZone'),
          content: (
            <CommonPopup
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
              inputTrue
              modelName="enableIntradayPassageModeTimeZone"
            />
          ),
        });
        setModalOpen(true);
      } else if (value === 'disableIntraday') {
        setModalView({
          title: t('disableIntradayPassageModeTimeZone'),
          content: (
            <CommonPopup
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
              inputTrue
              modelName="disableIntradayPassageModeTimeZone"
            />
          ),
        });
        setModalOpen(true);
      }
    } else {
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
      setModalOpen(true);
    }
  };

  const changeValue = (value: any) => {
    handleClick(value);
  };

  const moreActionButton = [
    {
      title: t('remoteOpening'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('remoteOpening'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                inputTrue
                modelName="remoteOpen"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('remoteClosing'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('remoteClosing'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                inputTrue
                modelName="remoteClose"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('enable'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('enable'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('areYouWantToPerformTheEnableOperation')}
                buttonLabel={t('ok')}
                cancelTrue
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('disable'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('disable'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('areYouWantToPerformTheDisableOperation')}
                buttonLabel={t('ok')}
                cancelTrue
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('cancelAlarm'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('cancelAlarm'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                inputTrue
                modelName="cancelAlarm"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('remoteNormallyOpen'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('remoteNormallyOpen'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                inputTrue
                modelName="remoteNormallyOpen"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      dropdown: (
        <ListDropdown
          data={options}
          label={t('lockDown&timezone')}
          changeFunction={changeValue}
          disabled={selectedData.length > 0}
        />
      ),
      clickFunction: handleClick,
    },
  ];

  const getFilterData = (filter: any): any => {
    console.log('Filter applied:', filter);
  };

  const fetchData = (): any => {
    console.log('Fetching next page data...');
  };

  useEffect(() => {
    console.log('Initial data fetching...');
  }, []);

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('door'),
          },
        ]}
      />
      <CardBox
        title={t('listOfDevice')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
            buttonFunction={(): void => {}}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedDoor}
          getSelectedData={(val: any): any => setSelectedDoor(val)}
          getSelectedFullData={(val: any): any => setSelectedData(val)}
          checkBoxActive
          fetchNextPage={fetchData}
          isFetching={false} // Placeholder status
          values={readerData} // Use the actual data here
          fixedColumn={['doorName']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Door;
