import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';

function Acknowledge({ closeModal, data }: any) {
  const { t } = useTranslation();
  const [status, setStatus] = useState('process');
  const [description, setDescription] = useState('');
  const submit = () => {
    console.log('hi');
  };
  console.log(data);
  return (
    <div className="acknowledge">
      <div className="flex-row column">
        <div className="row-1">
          <h2>{t('eventDetails')}</h2>
          <div className="box">
            <span>
              {data?.eventDescription}
              {data?.device}
            </span>
          </div>
        </div>
        <RadioButtonField
          label=""
          name="onlineStatus"
          aliment=""
          data={[
            {
              label: t('processing'),
              id: 'process',
              value: 'process',
            },
            {
              label: t('confirmed'),
              id: 'confirm',
              value: 'confirm',
            },
          ]}
          value={status}
          onChangeFunction={(name: string, value: any) => setStatus(value)}
        />
        <div className="row-1">
          <h2>{t('processingRecords')}</h2>
          <InputTextAreaField
            id="description"
            value={description} // Provide a fallback value
            label=""
            name="description"
            onChangeFunction={(name, value) => setDescription(value)}
          />
        </div>
        <div className="form-buttons-right">
          <Button onClickFunction={submit} title={t('ok')} className="btn-default" />
          <Button onClickFunction={closeModal} title={t('cancel')} className="btn-primary" />
        </div>
      </div>
    </div>
  );
}

export default Acknowledge;
