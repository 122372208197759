import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../redux/store';

// Import shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import EditEventType from './components/edit-eventType';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
import SetAudio from './set-audio';
import { setSearchFilter } from '../../redux/slices/EventTypeSlice';

function EventType() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { totalDataCount, searchFilter, currentPageNo } = useAppSelector(
    (state: any) => state.eventType,
  );
  // Sample data for the table
  const readerData = [
    {
      eventName: 'Normal Verify Open',
      eventNumber: '0',
      eventLevel: 'Normal',
      deviceName: 'GO Account Device-1',
      serialNumber: '7823788391',
      id: '0',
    },
    {
      eventName: 'First Personnel Open',
      eventNumber: '1',
      eventLevel: 'Exception',
      deviceName: 'GO Account Device-2',
      serialNumber: '78237a8839',
      id: '1',
    },
    {
      eventName: 'First Personnel Open-2',
      eventNumber: '2',
      eventLevel: 'Alarm',
      deviceName: 'GO Account Device-2',
      serialNumber: '78237883a9',
      id: '2',
    },
  ];

  const eventNameList = Array.from(new Set(readerData.map((data) => data.eventName)));

  const columnsField = [
    {
      accessorKey: 'eventName',
      header: t('eventName'),
      filterVariant: 'multi-select',
      filterSelectOptions: eventNameList,
      filterFn: 'arrIncludesSome',
    },
    {
      accessorKey: 'eventNumber',
      header: t('eventNumber'),
      enableColumnFilter: false,
    },
    {
      accessorKey: 'eventLevel',
      header: t('eventLevel'),
      filterVariant: 'select',
      filterSelectOptions: Array.from(new Set(readerData.map((data) => data.eventLevel))),
      Cell: ({ row }: any) => {
        let result;
        if (row?.original?.eventLevel === 'Normal') {
          result = <span className="text-success">{row?.original?.eventLevel}</span>;
        } else if (row?.original?.eventLevel === 'Exception') {
          result = <span style={{ color: '#FF9500' }}>{row?.original?.eventLevel}</span>;
        } else {
          result = <span className="text-warning">{row?.original?.eventLevel}</span>;
        }
        return result;
      },
    },
    {
      accessorKey: 'deviceName',
      header: t('deviceName'),
      enableColumnFilter: false,
    },
    {
      accessorKey: 'serialNumber',
      header: t('serialNumber'),
      enableColumnFilter: false,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedEvent, setSelectedEvent] = useState<string[]>([]);

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => {
        if (!modalOpen) {
          setModalOpen(true);
          setModalView({
            title: t('edit'),
            content: <EditEventType closeModal={() => setModalOpen(false)} data={{ ...val }} />,
          });
        }
      },
    },
  ];

  const moreActionButton = [
    {
      title: t('setAudio'),
      clickFunction: (): void => {
        if (selectedEvent.length > 0) {
          setModalView({
            title: t('setAudio'),
            content: (
              <SetAudio closeModal={() => setModalOpen(false)} selectedValues={selectedEvent} />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
    }
  };

  const fetchData = (): any => {
    console.log('Fetching next page data...');
  };

  useEffect(() => {
    console.log('Initial data fetching...');
  }, []);

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('eventType'),
          },
        ]}
      />
      <CardBox
        title={t('listOfReader')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
            buttonFunction={(): void => {}}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          enableColumnFilters
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedEvent}
          getSelectedData={(val: any): any => setSelectedEvent(val)}
          checkBoxActive
          fetchNextPage={fetchData}
          isFetching={false} // Placeholder status
          values={readerData} // Use the actual data here
          fixedColumn={['readerName']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default EventType;
